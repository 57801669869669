// Common CSS
body {
  margin: 0;
  font-family:'Open Sans','HelveticaNeue','Helvetica Neue',Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@media screen and (max-width: 768px){
  body {
    font-size: 12px;
  }
}

.lightbox-backdrop {
  z-index: 1000;
}

.cursor-pointer {
  cursor: pointer;
}

.valign-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .valign-wrapper {
    display: table;
    height: 100%;
    width: 100%;
    background-color: rgba(7, 7, 7, 0.5);
  }

  .valign-object {
    display: table-cell;
    vertical-align: middle;
  }

  .description {
    width: 40%;
    color: white;
    position: relative;
    margin-left: 50%;
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(200deg); }
}

@keyframes fade-up {
  0% { top: 100px; }
  100% { top: 0; }
}
