@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

@media (min-width: 1200px) {
  .container {
    max-width: 930px !important;
  }
}

button,
button:active,
button:focus,
button:hover {
  outline: none !important;
}

.w-80 {
  width: 80%;
}

input,
input:active,
input:focus,
input:hover {
  outline: none !important;
}

a {
  color: #153d8a;
}

a:hover,
a:active {
  text-decoration: none;
  color: #e30713;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.carousel .slide {
  background-color: white !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.8 !important;
}

// Custom scroll bar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}
